import { useEffect, useState } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { ModalBorrarVehiculo } from "./ModalBorrarVehiculo";
import { ModalEditarVehiculo } from "./ModalEditarVehiculo";
import edit_icon from "../../assets/images/edit-icon.svg";
import delete_icon from "../../assets/images/delete-icon.svg";
import reactivate_icon from "../../assets/images/reactivate-icon.svg";
import { useTranslation } from "react-i18next";
import { Pagination } from "../ui/Pagination";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { ModalReactivarVehiculo } from "./ModalReactivarVehiculo";
import { ModalNuevoVehiculo } from "./ModalNuevoVehiculo";
import { use } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { ModalInfoBloqueado } from "./ModalInfoBloqueado";
import { useDispatch } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export const TablaVehiculosFlota = ({
  vehiculos,
  token,
  permisos,
  activo,
  onChange,
  orderData,
}) => {
  const [modalEditar, setModalEditar] = useState({
    show: false,
    id_tc: null,
    credito_definido: null,
    credito_consumido: null,
    matricula: null,
    descripcion: null,
    max_operacion: null,
    max_imp_red: null,
    max_imp_gas: null,
    max_op_red: null,
    max_op_gas: null,
    max_op_30: null,
    id_tar: null,
    tipo_vehiculo: null,
  });
  const [modalBorrar, setModalBorrar] = useState({
    show: false,
    vehiculo: null,
  });
  const [modalReactivar, setModalReactivar] = useState({
    show: false,
    vehiculo: null,
  });

  const [modalNuevo, setModalNuevo] = useState({
    show: false,
    vehiculo: null,
  });

  const [modalInfoBloqueado, setModalInfoBloqueado] = useState({
    show: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const nPages = Math.ceil(vehiculos.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = vehiculos.slice(indexOfFirstRecord, indexOfLastRecord);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const switchEstado = (estado) => {
    switch (estado) {
      case "1":
      case 1:
        return (
          <Alert variant="success">{t("flotas.table.estado-activo")}</Alert>
        );
      case "0":
      case 0:
        return (
          <Alert variant="default">{t("flotas.table.estado-inactivo")}</Alert>
        );
      default:
        return (
          <Alert variant="warning">{t("flotas.table.estado-bloqueado")}</Alert>
        );
    }
  };

  const normalizarMatricula = (matricula) => {
    matricula = matricula.split("-");
    matricula = matricula[matricula.length - 1];

    return matricula;
  };

  const updateRow = (id, tipo, info = null) => {
    onChange();
  };

  const fetchTarjetaData = async (id) => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(`flotas/infoTarjeta/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setModalEditar({
            show: true,
            id_tc: data.IdTc,
            id_tar: data.IdTar,
            credito_definido: data.credito_definido,
            credito_consumido: data.credito_consumido,
            matricula: data.matricula,
            factura: data.factura,
            descripcion: data.descripcion,
            max_operacion: data.max_operacion,
            max_imp_red: data.max_imp_red,
            max_imp_gas: data.max_imp_gas,
            max_op_red: data.max_op_red,
            max_op_gas: data.max_op_gas,
            max_op_30: data.max_op_30,
            tipo_vehiculo: data.tipo_vehiculo,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      toast(t("flotas.table.editar-desactivada"), {
        icon: <LogoPetroprix />,
      });
    }
  };

  return (
    <>
      <Table hover={false} className="flotas" borderless={true}>
        <thead>
          <tr>
            <th>
              {t("flotas.table.matricula")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 0)} />
                <AiFillCaretUp onClick={orderData(true, 0)} />
              </span>
            </th>
            <th>
              {t("flotas.table.vehiculo")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 1)} />
                <AiFillCaretUp onClick={orderData(true, 1)} />
              </span>
            </th>
            <th>
              {t("flotas.table.combustible")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 2)} />
                <AiFillCaretUp onClick={orderData(true, 2)} />
              </span>
            </th>
            <th>
              {t("flotas.table.cred-definido")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 3)} />
                <AiFillCaretUp onClick={orderData(true, 3)} />
              </span>
            </th>
            <th>
              {t("flotas.table.cred-consumido")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 4)} />
                <AiFillCaretUp onClick={orderData(true, 4)} />
              </span>
            </th>
            <th>
              {t("flotas.table.estado")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 5)} />
                <AiFillCaretUp onClick={orderData(true, 5)} />
              </span>
            </th>
            {permisos && activo === "1" ? (
              <th>{t("flotas.table.acciones")}</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((vehiculo) => (
            <tr key={vehiculo.idTar} id={"vehiculo-" + vehiculo.idTar}>
              <td className="flotas-matricula">
                {normalizarMatricula(vehiculo.matricula)}
              </td>
              <td className="flotas-vehiculo">
                {vehiculo.tipo_vehiculo !== null ? vehiculo.tipo_vehiculo : "-"}
              </td>
              <td className="flotas-combustible">
                {vehiculo.tipo_combustible === "Gasolina" ? (
                  <Alert variant="gasolina">
                    {t("flotas.table.combustible.gasolina")}
                  </Alert>
                ) : vehiculo.tipo_combustible === "Diesel" ? (
                  <Alert variant="diesel">
                    {t("flotas.table.combustible.diesel")}
                  </Alert>
                ) : (
                  <Alert variant="default">-</Alert>
                )}
              </td>
              <td className="flotas-credito">{vehiculo.credito_definido}</td>
              <td className="flotas-credito">{vehiculo.credito_consumido} €</td>
              <td className="flotas-estado" id={"estado-" + vehiculo.idTar}>
                {switchEstado(vehiculo.activo)}
              </td>
              <td className="flotas-btn" id={"btns-" + vehiculo.idTar}>
                {permisos && activo == "1" ? (
                  vehiculo.activo == "1" ? (
                    vehiculo.bloqueado == "0" ? (
                      <>
                        <Button
                          variant="light"
                          className="user-config table-btn"
                        >
                          <img
                            src={edit_icon}
                            alt="Editar vehículo"
                            className="edit-icon-btn"
                            onClick={() => fetchTarjetaData(vehiculo.idTc)}
                          />
                        </Button>
                        <Button
                          variant="light"
                          className="user-config table-btn"
                        >
                          <img
                            src={delete_icon}
                            alt="Borrar vehículo"
                            className="delete-icon-btn"
                            onClick={() =>
                              setModalBorrar({
                                show: true,
                                vehiculo: vehiculo,
                              })
                            }
                          />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="light"
                        className="user-config table-btn btn-icon-font"
                      >
                        <FontAwesomeIcon
                          icon={faLock}
                          className="table-btn block-icon-btn"
                          onClick={() => setModalInfoBloqueado({ show: true })}
                        />
                      </Button>
                    )
                  ) : (
                    vehiculo.activo == "0" && (
                      <Button variant="light" className="user-config table-btn">
                        <img
                          src={reactivate_icon}
                          alt="Reactivar vehículo"
                          className="reactivate-icon-btn"
                          onClick={() =>
                            setModalReactivar({
                              show: true,
                              vehiculo: vehiculo,
                            })
                          }
                        />
                      </Button>
                    )
                  )
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={vehiculos.length}
        setRecordsPerPage={setRecordsPerPage}
      />

      <ModalEditarVehiculo
        show={modalEditar.show}
        onHide={(edited = false) => {
          if (edited) {
            // fetchVehicleData();
            updateRow(modalEditar.id_tar, "reactivate");
          }
          setModalEditar({ ...modalEditar, show: false });
        }}
        id_tc={modalEditar.id_tc}
        id_tar={modalEditar.id_tar}
        matricula={modalEditar.matricula}
        descripcion={modalEditar.descripcion}
        credito_definido={modalEditar.credito_definido}
        credito_consumido={modalEditar.credito_consumido}
        factura={modalEditar.factura}
        max_operacion={modalEditar.max_operacion}
        max_imp_red={modalEditar.max_imp_red}
        max_imp_gas={modalEditar.max_imp_gas}
        max_op_gas={modalEditar.max_op_gas}
        max_op_red={modalEditar.max_op_red}
        max_op_30={modalEditar.max_op_30}
        tipo_vehiculo={modalEditar.tipo_vehiculo}
      />

      <ModalBorrarVehiculo
        show={modalBorrar.show}
        onHide={(deleted = false, personales = false) => {
          if (deleted && personales) {
            updateRow(modalBorrar.vehiculo.idTar, "delete");
          } else if (deleted && !personales) {
            updateRow(modalBorrar.vehiculo.idTar, "desactivate");
          }
          setModalBorrar({ ...modalBorrar, show: false });
        }}
        vehiculo={modalBorrar.vehiculo}
      />

      <ModalReactivarVehiculo
        show={modalReactivar.show}
        onHide={(create = false, reactivated = false) => {
          setModalReactivar({ show: false });
          if (create) {
            setModalNuevo({
              show: true,
              vehiculo: modalReactivar.vehiculo,
              validated: false,
            });
          }
          if (reactivated) {
            updateRow(modalReactivar.vehiculo.idTar, "reactivate");
          }
        }}
        vehiculo={modalReactivar.vehiculo}
      />

      <ModalNuevoVehiculo
        show={modalNuevo.show}
        onHide={(create = false, data = null) => {
          setModalNuevo({ ...modalNuevo, show: false });
          if (create) {
            updateRow(modalNuevo.vehiculo.idTar, "create", data);
          }
        }}
        vehiculo={modalNuevo.vehiculo}
      />

      <ModalInfoBloqueado
        show={modalInfoBloqueado.show}
        onHide={() =>
          setModalInfoBloqueado({ ...modalInfoBloqueado, show: false })
        }
      />
    </>
  );
};
