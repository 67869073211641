import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectAnios } from "../ui/filtros/SelectAnios";
import { SelectMatriculas } from "../ui/filtros/SelectMatriculas";
import { SelectMeses } from "../ui/filtros/SelectMeses";
import { SelectVehiculos } from "../ui/filtros/SelectVehiculos";
import "../ui/filtros/mobile/selectMobile.css";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export const FiltrosRepostajes = ({
  url,
  token,
  matriculas,
  vehiculos,
  setRepostajes,
  setLoading,
  loadingVehicles,
  setLoadingVehicles,
  setMatriculas,
  setTerminoBusqueda,
  setFiltered,
  setResultadosFiltrados,
}) => {
  const { t } = useTranslation();
  const { flotas, flotaActiva } = useSelector((state) => state.auth);
  const [hasFlotas, setHasFlotas] = useState(flotas);
  const dispatch = useDispatch();

  const monthNames = [
    t("repostajes.meses.enero"),
    t("repostajes.meses.febrero"),
    t("repostajes.meses.marzo"),
    t("repostajes.meses.abril"),
    t("repostajes.meses.mayo"),
    t("repostajes.meses.junio"),
    t("repostajes.meses.julio"),
    t("repostajes.meses.agosto"),
    t("repostajes.meses.septiembre"),
    t("repostajes.meses.octubre"),
    t("repostajes.meses.noviembre"),
    t("repostajes.meses.diciembre"),
  ];

  let today = new Date();
  let d;
  let monthList = [];
  let monthListActual = [];
  let monthListAnterior = [];
  let yearList = [];
  let monthYearList = [];

  // Obtenemos los meses que han transcurrido de este año
  for (let i = 0; i < today.getMonth() + 1; i++) {
    d = new Date(today.getFullYear(), i, 1);
    monthListActual[i] = [
      d.getFullYear(),
      monthNames[d.getMonth()],
      d.getMonth(),
    ];
    if (!yearList.includes(d.getFullYear())) {
      yearList.push(d.getFullYear());
    }
  }

  //Le damos la vuelta al array para que aparezca primero el mes actual
  monthListActual.reverse();

  // Obtenemos todos los meses del año anterior si es posterio al año 2023
  if (d.getFullYear() - 1 > 2024) {
    for (let i = 0; i < 12; i++) {
      d = new Date(today.getFullYear() - 1, i, 1);
      monthListAnterior.push([
        d.getFullYear(),
        monthNames[d.getMonth()],
        d.getMonth(),
      ]);
      if (!yearList.includes(d.getFullYear())) {
        yearList.push(d.getFullYear());
      }
    }

    monthListAnterior.reverse();
  } else {
    monthListAnterior = [];
  }
  // Unimos los dos arrays
  monthList = monthListActual.concat(monthListAnterior);

  const [year, setYear] = useState(yearList[0]);

  for (let i = 0; i < monthList.length; i++) {
    if (monthList[i][0] === year) {
      let mes = [];
      mes[0] = monthList[i][1];
      mes[1] = monthList[i][2];
      monthYearList.push(mes);
    }
  }

  const [month, setMonth] = useState(monthList[0][1]);
  const [filtroMatriculas, setFiltroMatriculas] = useState(matriculas);
  const [filtrosAplicados, setFiltrosAplicados] = useState(false);
  const [filtroVehiculos, setFiltroVehiculos] = useState(["0", "1", "2"]);
  const [repostajesTotal, setRepostajesTotal] = useState([]);

  const fetchRepostajes = useCallback(
    async (id_cuenta = flotaActiva?.id_cuenta) => {
      let mes = 0;
      for (let i = 0; i < monthYearList.length; i++) {
        if (monthYearList[i][0] === month) {
          mes = monthYearList[i][1];
        }
      }

      let url_get_repostajes = `${url}?mes=${mes}&anio=${year}`;
      if (hasFlotas != "0") {
        url_get_repostajes = `${url_get_repostajes}&id_cuenta=${id_cuenta}`;
      }

      try {
        setLoading(true);
        setLoadingVehicles(true);
        setMatriculas([]);
        setRepostajes([]);
        setRepostajesTotal([]);
        setResultadosFiltrados([]);
        setTerminoBusqueda("");
        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.get(url_get_repostajes, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          });
          setLoading(false);
          setLoadingVehicles(false);
          setRepostajes([]);
          setRepostajesTotal([]);
          setMatriculas([]);
          setFiltroMatriculas([]);
          setResultadosFiltrados([]);
          setFiltered(false);
          if (response?.data?.code === 200) {
            let data = response.data.data;
            // Pasamos los datos al padre
            if (filtrosAplicados) {
              handleFilterRepostajes(data.repostajes, true);
            } else {
              setRepostajes(data.repostajes);
              setRepostajesTotal(data.repostajes);
            }
            setMatriculas(data.matriculas);
            setFiltroMatriculas(data.matriculas);
          } else if (response?.data?.code === 404) {
            console.warn(response.data.message);
          } else {
            console.warn(response.data.message);
          }
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        setLoading(false);
        setLoadingVehicles(false);
        console.error(error);
      }
    },
    [year, month]
  );

  useEffect(() => {
    // Comprobamos si en el año seleccionado se ha llegado al mes seleccionado, sino cambiamos al mes actual del año seleccionado
    let existe = false;
    if (monthYearList.length < 12) {
      for (let i = 0; i < monthYearList.length; i++) {
        if (monthYearList[i].includes(month)) {
          existe = true;
          break;
        }
      }
      if (!existe) {
        setMonth(monthYearList[0][0]);
      }
    }

    setYear(year);
    fetchRepostajes();
  }, [year]);

  useEffect(() => {
    fetchRepostajes();
    setMonth(month);
  }, [month]);

  useEffect(() => {
    if (hasFlotas) {
      fetchRepostajes(flotaActiva?.id_cuenta);
    }
  }, [flotaActiva, hasFlotas]);

  const handleFilterRepostajes = (repostajesTotal, soloVehiculos = false) => {
    let results_temp = [];
    let results = [];
    let filtroMatricula = false;
    let filtroVehiculo = false;
    if (filtrosAplicados) {
      // Filtramos en primer lugar por vehiculos si hay alguno seleccionado
      if (filtroVehiculos.length < 3) {
        for (let i = 0; i < filtroVehiculos.length; i++) {
          for (let j = 0; j < repostajesTotal.length; j++) {
            if (repostajesTotal[j].tipo_vehiculo == filtroVehiculos[i]) {
              results.push(repostajesTotal[j]);
            }
          }
        }
        filtroVehiculo = true;
      } else {
        results = [...repostajesTotal];
      }

      if (!soloVehiculos) {
        // Filtramos en segundo lugar por matriculas si hay alguna seleccionada
        if (filtroMatriculas.length < matriculas.length) {
          for (let i = 0; i < filtroMatriculas.length; i++) {
            for (let j = 0; j < results.length; j++) {
              if (
                results[j].matricula.toLowerCase().trim() ===
                filtroMatriculas[i].toLowerCase().trim()
              ) {
                results_temp.push(repostajesTotal[j]);
              }
            }
          }
          results = [...results_temp];
          filtroMatricula = true;
        }
      }

      setFiltered(true);
      setResultadosFiltrados(results);
      setRepostajesTotal(repostajesTotal);
    }
  };

  useEffect(() => {
    // Llamamos al método para filtrar las matriculas
    setFiltrosAplicados(true);
    setTerminoBusqueda("");
    handleFilterRepostajes(repostajesTotal);
  }, [filtroMatriculas, filtroVehiculos]);

  return (
    <>
      <SelectMatriculas
        items={matriculas}
        actual={matriculas}
        onChange={setFiltroMatriculas}
        loadingVehicles={loadingVehicles}
      />

      <SelectVehiculos
        items={vehiculos}
        actual={vehiculos}
        onChange={setFiltroVehiculos}
      />
      <SelectMeses items={monthYearList} actual={month} onChange={setMonth} />
      <SelectAnios items={yearList} actual={year} onChange={setYear} />
    </>
  );
};
