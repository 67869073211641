import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import makerIcon from "../../assets/images/marker-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_GASOLINERA_CLIENTE = "cliente/gasolinera";

export const GasolineraCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [gasolineraCliente, setGasolineraCliente] = useState({});
  const [loadedGasstation, setLoadedGasstation] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState({
    center: {
      lat: 40.416775,
      lng: -3.70379,
    },
    zoom: 11,
  });
  const [hasGasstationSave, setHasGasstationSave] = useState(false);

  const fetchGasStationData = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_GASOLINERA_CLIENTE, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          if (
            data.id !== null &&
            data.id !== undefined &&
            data.id !== "" &&
            data.id !== 0 &&
            data.id !== -1
          ) {
            setHasGasstationSave(true);
            setGasolineraCliente({
              id: data.id,
              nombre: data.nombre,
              direccion: data.direccion,
              latitud: String(data.lat),
              longitud: String(data.lon),
              p_diesel: data.p_diesel,
              p_gasolina: data.p_gasolina,
              cp: data.cp,
            });
            setDefaultCenter({
              center: {
                lat: Number(data.lat),
                lng: data.lon,
              },
              zoom: 11,
            });
            setLoadedGasstation(true);
          } else {
            setHasGasstationSave(false);
            setLoadedGasstation(true);
          }
        } else {
          setHasGasstationSave(false);
          setLoadedGasstation(true);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoadedGasstation(true);
    }
  }, []);

  // Actualizamos el estado de vehiculos cada vez que se añade uno nuevo
  useEffect(() => {
    fetchGasStationData();
  }, [fetchGasStationData]);

  const handleGasolinerasList = () => {
    if (ValidateToken()) {
      navigate("/gasolineras");
    } else {
      dispatch(startLogout("expired"));
    }
  };

  const MarkerIcon = ({ text }) => (
    <div>
      <img
        src={makerIcon}
        className="datos-gasolinera-marker-icon"
        alt={text}
      />
    </div>
  );

  return (
    <Container className="section pt-4">
      <Row>
        <Col xs={7} className="section-header-text">
          <h2>{t("datos.gasolinera.card.h2")}</h2>
        </Col>
        <Col className="section-header-btn">
          <Button variant="primary" onClick={handleGasolinerasList}>
            {t("datos.gasolinera.card.listado-btn")}
          </Button>
        </Col>
      </Row>
      {!loadedGasstation ? (
        <div className="datos-map" style={{ height: "9em", width: "100%" }}>
          <LoaderPetroprix type="small" position="absolute" />
        </div>
      ) : hasGasstationSave ? (
        <>
          <Row className="mt-5">
            <div
              className="datos-map"
              style={{ height: "50vh", width: "100%" }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyB_16zqjKF1GNE01fu9QukJiWXpCuEODAQ",
                }}
                defaultCenter={[
                  defaultCenter.center.lat,
                  defaultCenter.center.lng,
                ]}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={defaultCenter.zoom}
                draggable={false}
                options={{
                  fullscreenControl: false,
                }}
              >
                <MarkerIcon
                  lat={gasolineraCliente.latitud}
                  lng={gasolineraCliente.longitud}
                  text={gasolineraCliente.nombre}
                />
              </GoogleMapReact>
            </div>
          </Row>
          <Row className="datos-fav mt-4 pb-3">
            <Col className="datos-gas" xs={8} lg={6}>
              <Row>
                <span className="datos-nom-gas">
                  {gasolineraCliente.nombre}
                </span>
              </Row>
              <Row>
                <span className="datos-dir-gas">
                  {gasolineraCliente.direccion}, {gasolineraCliente.cp}
                </span>
              </Row>
            </Col>
            <Col xs={4} lg={6}>
              <Row className="datos-precios">
                <Alert variant="diesel">{gasolineraCliente.p_diesel} €/L</Alert>

                <Alert variant="gasolina mt-2">
                  {gasolineraCliente.p_gasolina} €/L
                </Alert>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <div className="no-gasstation">
          <Alert variant="default">{t("datos.gasolinera.card.alert")}</Alert>
        </div>
      )}
    </Container>
  );
};
