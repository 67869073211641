// Funcion para formatear un numero de telefono con el siguiente formato +XXX XXX XXXX
//
export function formatTelefono(telefono) {
  if (!telefono) return "";
  let formatted = "";
  let count = 0;
  for (let i = 0; i < telefono.length; i++) {
    if (count === 4 || count === 7) {
      formatted += " ";
    }
    formatted += telefono[i];
    count++;
  }
  return formatted;
}
