import { useState } from "react";
import { Alert, InputGroup, Row, Table } from "react-bootstrap";
import { Pagination } from "../ui/Pagination";
import { t } from "i18next";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

export const TablaGasolineras = ({
  data,
  gasolineraCliente,
  changeFavorita,
  orderData,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(25);

  const nPages = Math.ceil(data.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <>
      <Table hover={false} className="datos-gasolinera">
        <thead>
          <tr>
            <th>{t("datos.gasolinera.list.table.fav")} </th>
            <th>
              {t("datos.gasolinera.list.table.gasolinera")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 1)} />
                <AiFillCaretUp onClick={orderData(true, 1)} />
              </span>
            </th>
            <th>
              {t("datos.gasolinera.list.table.provincia")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 2)} />
                <AiFillCaretUp onClick={orderData(true, 2)} />
              </span>
            </th>
            <th className="datos-precio-gas d-none d-lg-block">
              {t("datos.gasolinera.list.table.precio-diesel")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 3)} />
                <AiFillCaretUp onClick={orderData(true, 3)} />
              </span>
            </th>
            <th className="datos-precio-gas d-none d-lg-block">
              {t("datos.gasolinera.list.table.precio-gasolina")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 4)} />
                <AiFillCaretUp onClick={orderData(true, 4)} />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((gas) => (
            <tr key={gas.id}>
              <td>
                {gas.id == gasolineraCliente ? (
                  <InputGroup.Radio
                    name="favorita"
                    value={gas.id}
                    aria-label="Seleccionar favorita"
                    className="datos-btn-favorito"
                    onClick={changeFavorita}
                    defaultChecked
                  />
                ) : (
                  <InputGroup.Radio
                    name="favorita"
                    value={gas.id}
                    aria-label="Seleccionar favorita"
                    className="datos-btn-favorito"
                    onClick={changeFavorita}
                  />
                )}
              </td>
              <td>
                <Row>
                  <span className="datos-nom-gas">{gas.nombre}</span>
                </Row>
                <Row>
                  <span className="datos-dir-gas">
                    {gas.direccion}, {gas.cp} {gas.localidad}
                  </span>
                </Row>
              </td>
              <td className="datos-prov-gas">{gas.provincia}</td>
              <td className="datos-precio-gas d-none d-lg-block">
                <Alert variant="diesel">{gas.p_diesel} €/L</Alert>
              </td>
              <td className="datos-precio-gas d-none d-lg-block">
                <Alert variant="gasolina">{gas.p_gasolina} €/L</Alert>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={data.length}
        setRecordsPerPage={setRecordsPerPage}
      />
    </>
  );
};
